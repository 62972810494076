import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import _ from 'lodash';

export const getTotalSpecialEquipmentList = async (filter = {}) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput) {
			GetTotalSpecialEquipementList(Filter: $filter)
		}
	`;

	const {
		data: { GetTotalSpecialEquipementList }
	} = await apollo.query({
		query,
		variables: { filter },
		fetchPolicy: 'no-cache'
	});

	return GetTotalSpecialEquipementList;
};

export const getSpecialEquipmentList = async (filter = {}) => {
	const query = gql`
    query ($filter: AdvancedGlobalFilterInput) {
      GetSpecialEquipementList(Filter: $filter) {
        ${SPECIAL_EQUIPMENT_FIELDS}
      }
    }
  `;

	const {
		data: { GetSpecialEquipementList }
	} = await apollo.query({
		query,
		variables: { filter },
		fetchPolicy: 'no-cache'
	});

	return GetSpecialEquipementList;
};

export const getSpecialEquipment = async (specialEquipmentId) => {
	const query = gql`
    query ($specialEquipmentId: ID!) {
      GetSpecialEquipment(SpecialEquipmentId: $specialEquipmentId) {
        ${SPECIAL_EQUIPMENT_FIELDS}
      }
    }
  `;

	const {
		data: { GetSpecialEquipment }
	} = await apollo.query({
		query,
		variables: { specialEquipmentId: +specialEquipmentId },
		fetchPolicy: 'no-cache'
	});

	return GetSpecialEquipment;
};

export const addUpdSpecialEquipment = async (specialEquipmentInput = {}, specialEquipmentId = null) => {
	const mutation = gql`
    mutation ($specialEquipmentInput: SpecialEquipmentInput!, $specialEquipmentId: ID) {
      AddUpdSpecialEquipment(
        SpecialEquipmentInput: $specialEquipmentInput
        SpecialEquipmentId: $specialEquipmentId
      ) {
        ${SPECIAL_EQUIPMENT_FIELDS}
      }
    }
  `;

	const {
		data: { AddUpdSpecialEquipment }
	} = await apollo.mutate({
		mutation,
		variables: {
			specialEquipmentInput,
			specialEquipmentId: _.isNil(specialEquipmentId) ? null : +specialEquipmentId
		}
	});

	return AddUpdSpecialEquipment;
};

export const addUpdGeneratorConsumption = async (specialEquipmentId = null, inputs = []) => {
	const mutation = gql`
    mutation ($specialEquipmentId: ID!, $inputs: [GeneratorConsumptionInput]!) {
      AddUpdGeneratorConsumption(
        SpecialEquipmentId: $specialEquipmentId
        GeneratorConsumptionInputs: $inputs
      ) {
        ${GENERATOR_CONSUMPTION_FIELDS}
      }
    }
  `;

	const {
		data: { AddUpdGeneratorConsumption }
	} = await apollo.mutate({
		mutation,
		variables: {
			specialEquipmentId: _.isNil(specialEquipmentId) ? null : +specialEquipmentId,
			inputs
		}
	});

	return AddUpdGeneratorConsumption;
};

export const delSpecialEquipments = async (specialEquipmentIds) => {
	const mutation = gql`
		mutation ($specialEquipmentIds: [ID!]!) {
			DelSpecialEquipments(SpecialEquipmentId: $specialEquipmentIds)
		}
	`;

	const {
		data: { DelSpecialEquipments }
	} = await apollo.mutate({
		mutation,
		variables: {
			specialEquipmentIds
		}
	});

	return DelSpecialEquipments;
};

const GENERATOR_CONSUMPTION_FIELDS = `
  id
  createdAt
  updatedAt
  specialEquipmentTypeGeneratorId
  date
  consumption
  generatorConsumptionIdToUpd
`;

const SPECIAL_EQUIPMENT_FIELDS = `
  id
  createdAt
  updatedAt
  projectId
  type
  subType
  name
  preproduction
  shooting
  energyType
  dayInUse
  totalConsumption
  totalDistance
  energySourceUsed
  measurementOption
  totalCosts
`;
