var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "error-container",
    { attrs: { error: _vm.error } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "pos-relative",
            attrs: { id: "mainSpecialEquipmentsContainer" },
          },
          [
            _c(
              "div",
              {
                class:
                  _vm.$screen.width > 576
                    ? "container-layout"
                    : "container-mobile",
              },
              [
                _c(
                  "div",
                  { staticClass: "form" },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c(
                            "h1",
                            {
                              class: [
                                `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                                { "is-pwa": _vm.$isPwa() },
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.FormMSG(18, "Special equipments"))
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "bg-color-white mt-3" },
                      [
                        _c("b-col", [
                          _c(
                            "div",
                            {
                              staticClass: "pos-relative",
                              class: `${
                                _vm.$screen.width <= 576 ? "my-project" : ""
                              }`,
                            },
                            [
                              _c(
                                "b-tabs",
                                {
                                  attrs: {
                                    value: _vm.specialEquipmentsTabs,
                                    cards: "",
                                    "active-nav-item-class": "font-weight-bold",
                                    "active-tab-class": "font-weight-bold",
                                  },
                                  model: {
                                    value: _vm.tabIndex,
                                    callback: function ($$v) {
                                      _vm.tabIndex = $$v
                                    },
                                    expression: "tabIndex",
                                  },
                                },
                                [
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: _vm.FormMSG(52, "Generators"),
                                      },
                                    },
                                    [_c("div", [_c("list-generators")], 1)]
                                  ),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: _vm.FormMSG(
                                          53,
                                          "Production control rooms"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [_c("list-production-control-rooms")],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: _vm.FormMSG(54, "Trailers"),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [_c("list-production-trailers")],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }