<template>
	<error-container :error="error">
		<transition name="slide">
			<div class="pos-relative" id="mainSpecialEquipmentsContainer">
				<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
					<div class="form">
						<b-row>
							<b-col>
								<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(18, 'Special equipments') }}</h1>
							</b-col>
						</b-row>

						<b-row class="bg-color-white mt-3">
							<b-col>
								<div class="pos-relative" :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
									<b-tabs
										:value="specialEquipmentsTabs"
										cards
										active-nav-item-class="font-weight-bold"
										active-tab-class="font-weight-bold"
										v-model="tabIndex"
									>
										<b-tab :title="FormMSG(52, 'Generators')">
											<div>
												<list-generators />
											</div>
										</b-tab>
										<b-tab :title="FormMSG(53, 'Production control rooms')">
											<div>
												<list-production-control-rooms />
											</div>
										</b-tab>
										<b-tab :title="FormMSG(54, 'Trailers')">
											<div>
												<list-production-trailers />
											</div>
										</b-tab>
									</b-tabs>
								</div>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</transition>
	</error-container>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import ListGenerators from '../../components/special-equipments/ListGenerators.vue';
import ListProductionControlRooms from '../../components/special-equipments/ListProductionControlRooms.vue';
import ListProductionTrailers from '../../components/special-equipments/ListProductionTrailers.vue';

export default {
	name: 'MainSpecialEqupments',
	components: { ListProductionTrailers, ListProductionControlRooms, ListGenerators },

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			error: {},
			specialEquipmentsTabs: 0,
			tabIndex: 0
		};
	}
};
</script>

<style scoped></style>
