<template>
	<div>
		<list-equipments-component :equipment-type="equipmentType" :btn-add-label="FormMSG(264, 'Add equipment')" />
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { SPE_PRODUCTION_ROOMS_TYPE } from '../../../../shared/constants';
import ListEquipmentsComponent from './ListEquipmentsComponent.vue';

export default {
	name: 'ListProductionControlRooms',
	components: { ListEquipmentsComponent },

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			equipmentType: SPE_PRODUCTION_ROOMS_TYPE
		};
	}
};
</script>
