<template>
	<b-modal
		id="modal-document-package"
		v-model="isOpen"
		:title="FormMSG(79, 'New equipment')"
		header-class="header-class-modal-doc-package"
		size="xl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		:ok-title="FormMSG(36, 'Save')"
		ok-variant="primary"
		:ok-disabled="loadingSubmit"
		:cancel-title="FormMSG(39, 'Cancel')"
		:cancel-disabled="loadingSubmit"
		cancel-variant="custom-outline-gray"
		@ok.prevent="handleOk"
		@hidden="emitEventClose"
		@cancel="emitEventClose"
	>
		<div>
			<b-row v-if="stateError.show">
				<b-col>
					<b-alert variant="danger" show dismissible @dismissed="handleDismissedError">
						{{ stateError.msg }}
					</b-alert>
				</b-col>
			</b-row>
			<b-row v-if="isGeneratorType">
				<b-col cols="4" offset="8">
					<div class="d-flex justify-content-end">
						<div class="d-flex">
							<div class="fs-14 fw-700 pt-2 w-200-px">
								{{ FormMSG(64, 'Generator No.') }}
							</div>
							<div>
								<b-form-input class="bg-color-grams-hair" :value="equipmentId === null ? '' : equipmentId" disabled />
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
			<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
				<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
					{{ FormMSG(390, 'General') }}
				</legend>
				<div class="pt-3 pb-4">
					<b-row>
						<b-col sm="12" md="3" lg="3" xl="3">
							<b-form-group :label="FormMSG(391, 'Name')">
								<b-form-input
									v-model="$v.dataToSend.name.$model"
									:state="getStateValidationField('dataToSend.name')"
									:placeholder="FormMSG(754, 'Enter name')"
								/>
								<div v-if="$v.dataToSend.name.$error && getStateValidationField('dataToSend.name') !== null" class="invalid-feedback">
									{{ FormMSG(755, 'Please enter name') }}
								</div>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="3" lg="3" xl="3">
							<b-form-group :label="typeLabel">
								<b-form-select
									v-model="$v.dataToSend.subType.$model"
									:options="generatorTypeOptions"
									:state="getStateValidationField('dataToSend.subType')"
								/>
								<div v-if="$v.dataToSend.subType.$error && getStateValidationField('dataToSend.subType') !== null" class="invalid-feedback">
									{{ FormMSG(759, 'Please select generator type') }}
								</div>
							</b-form-group>
						</b-col>
						<b-col v-if="isGeneratorType || isTrailerType" sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="FormMSG(393, 'Production phase')" v-slot="{ ariaDescribedby }" class="pt-2">
								<b-form-checkbox-group v-model="productionPhaseSelected" :aria-describedby="ariaDescribedby" name="radio-production-phase">
									<b-form-checkbox :value="'preproduction'" :unchecked-value="false" size="lg">{{
										FormMSG(394, 'Preproduction')
									}}</b-form-checkbox>
									<b-form-checkbox :value="'shooting'" :unchecked-value="false" size="lg">{{ FormMSG(395, 'Shooting') }}</b-form-checkbox>
								</b-form-checkbox-group>
							</b-form-group>
						</b-col>
						<b-col v-if="isProductionRoomsType" sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="FormMSG(3748, 'Measurement option')" v-slot="{ ariaDescribedby }" class="pt-2">
								<b-form-radio-group
									id="energy-source-used-radio"
									v-model="dataToSend.measurementOption"
									:aria-describedby="ariaDescribedby"
									name="radio"
								>
									<b-form-radio :value="0" size="lg">{{ FormMSG(3749, 'Technical mean') }}</b-form-radio>
									<b-form-radio :value="1" size="lg">{{ FormMSG(3750, 'Costs') }}</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</fieldset>
			<fieldset class="my-0 py-0 pb-0 mt-3" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
				<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
					{{ secondSectionLabel }}
				</legend>
				<div class="pt-3 pb-4">
					<b-row v-if="isGeneratorType">
						<b-col sm="12" md="3" lg="3" xl="3">
							<b-form-group :label="FormMSG(491, 'Energy type')">
								<b-form-select
									v-model="$v.dataToSend.energyType.$model"
									:options="energyTypeOptions"
									:state="getStateValidationField('dataToSend.energyType')"
								/>
								<div v-if="$v.dataToSend.subType.$error && getStateValidationField('dataToSend.energyType') !== null" class="invalid-feedback">
									{{ FormMSG(798, 'Please select energy type') }}
								</div>
							</b-form-group>
						</b-col>
						<b-col sm="12" md="3" lg="3" xl="3">
							<b-form-group v-if="$screen.width > 576" :label="FormMSG(498, 'Consumption distance')">
								<b-input-group>
									<b-form-input :value="dataToSend.totalConsumption" class="bg-color-grams-hair" disabled />
									<b-input-group-append>
										<b-input-group-text class="bg-color-north-wind text-color-white">
											{{ consumptionGeneratorUnit }}
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<div v-if="isProductionRoomsType">
						<b-row>
							<b-col v-if="!showCostForProductionRooms" sm="12" md="3" lg="3" xl="3">
								<b-form-group :label="FormMSG(4798, 'Day in used')">
									<b-form-input
										v-model="$v.dataToSend.dayInUse.$model"
										:state="getStateValidationField('dataToSend.dayInUse')"
										:placeholder="FormMSG(1258, 'Enter day in used')"
									/>
									<div
										v-if="$v.dataToSend.dayInUse.$error && getStateValidationField('dataToSend.dayInUse') !== null"
										class="invalid-feedback"
									>
										{{ FormMSG(1697, 'Please enter day in used') }}
									</div>
								</b-form-group>
							</b-col>
							<b-col v-if="showDistanceForProductionRooms" sm="12" md="3" lg="3" xl="3">
								<b-form-group :label="FormMSG(1254, 'Travel distance')">
									<b-input-group>
										<b-form-input :value="dataToSend.totalDistance" class="bg-color-grams-hair" disabled />
										<b-input-group-append>
											<b-input-group-text class="bg-color-north-wind text-color-white">
												{{ distanceUnit }}
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col v-if="showCostForProductionRooms" sm="12" md="3" lg="3" xl="3">
								<b-form-group :label="FormMSG(5124, 'Costs (excluded salaries)')">
									<b-input-group>
										<b-form-input :value="dataToSend.totalCosts" class="bg-color-grams-hair" disabled />
										<b-input-group-append>
											<b-input-group-text class="bg-color-north-wind text-color-white">
												<currency-svg color="#fff" opacity="0.85" width="15" height="15" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row v-if="showDistanceForProductionRooms">
							<b-col offset="3" cols="9">
								<div class="d-flex text-color-blue-streak fs-12" style="margin-top: -12px">
									<div class="mr-1">
										<component :is="getLucideIcon('Info')" :size="14" />
									</div>
									<div>
										{{
											FormMSG(
												1587,
												'You can edit travel distance adding a new Carbon Entry or editing an existing entry under Sustainability > Carbon'
											)
										}}
									</div>
								</div>
							</b-col>
						</b-row>
					</div>

					<div v-if="isTrailerType">
						<b-row>
							<b-col sm="12" md="3" lg="3" xl="3">
								<b-form-group :label="FormMSG(1254, 'Travel distance')">
									<b-input-group>
										<b-form-input :value="dataToSend.totalDistance" class="bg-color-grams-hair" disabled />
										<b-input-group-append>
											<b-input-group-text class="bg-color-north-wind text-color-white">
												{{ distanceUnit }}
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<div class="d-flex text-color-blue-streak fs-12" style="margin-top: -12px">
									<div class="mr-1">
										<component :is="getLucideIcon('Info')" :size="14" />
									</div>
									<div>
										{{
											FormMSG(
												1587,
												'You can edit travel distance adding a new Carbon Entry or editing an existing entry under Sustainability > Carbon'
											)
										}}
									</div>
								</div>
							</b-col>
						</b-row>
					</div>
				</div>
			</fieldset>

			<div>
				<fieldset
					v-if="(isGeneratorType && dataToSend.subType === 3) || isProductionRoomsType || isTrailerType"
					class="my-0 py-0 pb-0 mt-3"
					:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
				>
					<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
						{{ thirdSectionLabel }}
					</legend>
					<div class="pt-3 pb-4">
						<div v-if="isGeneratorType">
							<b-row>
								<b-col sm="12" md="3" lg="3" xl="3">
									<b-form-group :label="FormMSG(1254, 'Distance')">
										<b-input-group>
											<b-form-input v-model="dataToSend.totalDistance" class="bg-color-grams-hair" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ distanceUnit }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<div class="d-flex text-color-blue-streak fs-12" style="margin-top: -12px">
								<div class="mr-1">
									<component :is="getLucideIcon('Info')" :size="14" />
								</div>
								<div>
									{{
										FormMSG(
											1587,
											'You can edit travel distance adding a new Carbon Entry or editing an existing entry under Sustainability > Carbon'
										)
									}}
								</div>
							</div>
						</div>
						<div v-if="isProductionRoomsType || isTrailerType">
							<b-row>
								<b-col sm="12" md="6" lg="6" xl="6">
									<b-form-group :label="FormMSG(3698, 'Energy source used')" v-slot="{ ariaDescribedby }" class="pt-2">
										<b-form-radio-group
											id="energy-source-used-radio"
											v-model="dataToSend.energySourceUsed"
											:aria-describedby="ariaDescribedby"
											name="radio-energy-source"
										>
											<b-form-radio :value="0" size="lg">{{ FormMSG(1394, 'Filming location energy') }}</b-form-radio>
											<b-form-radio :value="1" size="lg">{{ FormMSG(1395, 'Generator') }}</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</div>
				</fieldset>
			</div>
		</div>

		<template #modal-ok>
			<b-spinner v-if="loadingSubmit" small />
			{{ FormMSG(36, 'Save') }}
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import { SPE_TRAILER_TYPE_MENU, SPE_GENERATOR_TYPE_MENU, SPE_EQUIPMENT_TYPE_MENU, SPE_GENERATOR_ENERGY_TYPE_MENU } from '@/shared/constants';
import { validationMixin } from 'vuelidate';
import { decimal, minValue, required } from 'vuelidate/lib/validators';
import { addUpdSpecialEquipment, addUpdGeneratorConsumption } from '@/cruds/special-equipment.crud';
import CurrencySvg from '@/components/Svg/Currency';

export default {
	name: 'AddUpdSpecialEquipment',

	components: { CurrencySvg },

	mixins: [languageMessages, globalMixin, validationMixin],

	/**
	 * specialEquipmentType:
	 * 1: Generators
	 * 2: Production control rooms
	 * 3: Trailers
	 */
	props: {
		open: { type: Boolean, default: false, required: true },
		// specialEquipmentData: { type: Object, default: () => ({}) },
		specialEquipmentType: { type: Number },
		forCreate: { type: Boolean, default: false },
		editData: { type: Object, default: () => ({}), required: false }
	},

	data() {
		return {
			loadingSubmit: false,
			equipmentType: 1,
			showGeneratorConsumptionDialog: false,
			productionPhaseSelected: [],
			stateError: {
				show: false,
				msg: ''
			},
			dataToSend: {
				type: 0,
				subType: 0,
				name: null,
				preproduction: 0,
				shooting: 0,
				energyType: 0,
				dayInUse: 0,
				specialEquipmentIdUpdate: 0,
				totalConsumption: 0,
				totalDistance: 0,
				totalCosts: 0,
				energySourceUsed: 0,
				measurementOption: 0
			},
			equipmentId: null,
			specialEquipmentId: 0,
			consumptionsToSave: []
		};
	},

	watch: {
		editData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.productionPhaseSelected = [];
					this.equipmentId = +newVal.id;

					this.dataToSend.name = newVal.name;
					this.dataToSend.type = newVal.type;
					this.dataToSend.subType = newVal.subType;
					this.dataToSend.preproduction = newVal.preproduction;
					this.dataToSend.shooting = newVal.shooting;
					this.dataToSend.energyType = newVal.energyType;
					this.dataToSend.dayInUse = newVal.dayInUse;
					this.dataToSend.specialEquipmentIdUpdate = newVal.specialEquipmentIdUpdate;
					this.dataToSend.totalConsumption = newVal.totalConsumption;
					this.dataToSend.totalDistance = newVal.totalDistance;
					this.dataToSend.totalCosts = newVal.totalCosts;
					this.dataToSend.measurementOption = newVal.measurementOption === true ? 1 : 0;
					this.dataToSend.energySourceUsed = newVal.energySourceUsed === true ? 1 : 0;

					if (newVal.preproduction === true) {
						this.productionPhaseSelected.push('preproduction');
					}

					if (newVal.shooting === true) {
						this.productionPhaseSelected.push('shooting');
					}
				}
			}
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		showDistanceForProductionRooms() {
			const EQUIPMENT_TRUCK = [3, 4, 5];
			if (this.isProductionRoomsType && this.dataToSend.measurementOption === 0) {
				return EQUIPMENT_TRUCK.includes(this.dataToSend.subType);
			}

			return false;
		},
		showCostForProductionRooms() {
			return this.isProductionRoomsType && this.dataToSend.measurementOption === 1;
		},
		consumptionGeneratorUnit() {
			if (this.dataToSend.energyType === 1 || this.dataToSend.energyType === 2) {
				return this.FormMSG(956, 'L');
			} else if (this.dataToSend.energyType === 3) {
				return this.FormMSG(957, 'm3');
			}

			return this.FormMSG(958, 'kg');
		},
		thirdSectionLabel() {
			if (this.isGeneratorType) {
				return this.FormMSG(985, 'Travel distance');
			} else if (this.isProductionRoomsType || this.isTrailerType) {
				return this.FormMSG(5288, 'Energy');
			}

			return '';
		},
		secondSectionLabel() {
			if (this.isGeneratorType) {
				return this.FormMSG(490, 'Consumption');
			} else if (this.isProductionRoomsType || this.isTrailerType) {
				return this.FormMSG(6415, 'Information');
			}

			return '';
		},
		isGeneratorType() {
			return this.specialEquipmentType === 1;
		},
		isProductionRoomsType() {
			return this.specialEquipmentType === 2;
		},
		isTrailerType() {
			return this.specialEquipmentType === 3;
		},
		typeLabel() {
			if (this.isGeneratorType) {
				return this.FormMSG(392, 'Generator type');
			} else if (this.isProductionRoomsType) {
				return this.FormMSG(1598, 'Equipment type');
			} else if (this.isTrailerType) {
				return this.FormMSG(1599, 'Trailer type');
			}

			return '';
		},
		generatorTypeOptions() {
			let menues = [];

			if (this.isGeneratorType) {
				menues = this.FormMenu(SPE_GENERATOR_TYPE_MENU);
			} else if (this.isProductionRoomsType) {
				menues = this.FormMenu(SPE_EQUIPMENT_TYPE_MENU);
			} else if (this.isTrailerType) {
				menues = this.FormMenu(SPE_TRAILER_TYPE_MENU);
			}
			return [{ text: this.FormMSG(258, 'Please select ...'), value: 0 }, ...menues];
		},
		energyTypeOptions() {
			return [{ text: this.FormMSG(258, 'Please select ...'), value: 0 }, ...this.FormMenu(SPE_GENERATOR_ENERGY_TYPE_MENU)];
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit.toLowerCase();
		}
	},

	methods: {
		handleGeneratorConsumptionDialogSave({ data }) {
			this.consumptionsToSave = data;
		},
		async handleOk() {
			try {
				this.$v.$touch();
				if (this.$v.$invalid) return;

				this.loadingSubmit = true;

				let input = this.prepareDataToSend();
				let result = await addUpdSpecialEquipment(input, this.equipmentId);

				if (this.isGeneratorType && this.consumptionsToSave.length > 0) {
					await addUpdGeneratorConsumption(+result.id, this.consumptionsToSave);
				}

				this.emitEventCreateSuccess(result);

				let success_msg = this.FormMSG(4875, 'The equipment added successfully!');
				if (!this.forCreate) {
					success_msg = this.FormMSG(5874, 'The equipment updated successfully!');
				}
				this.createToastForMobile(this.FormMSG(4874, 'Success'), success_msg);

				this.emitEventClose();
				this.loadingSubmit = false;
			} catch (e) {
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
				this.loadingSubmit = false;
			}
		},
		emitEventCreateSuccess(payload) {
			this.$emit('add-upd-special-equipment:action-success', {
				type: this.specialEquipmentType,
				data: payload,
				forCreate: this.forCreate
			});
		},
		prepareDataToSend() {
			let preproduction = false;
			let shooting = false;

			if (this.isProductionRoomsType) {
				preproduction = true;
				shooting = true;
			} else {
				for (let i = 0; i < this.productionPhaseSelected.length; i++) {
					if (this.productionPhaseSelected[i] === 'preproduction') {
						preproduction = true;
					}
					if (this.productionPhaseSelected[i] === 'shooting') {
						shooting = true;
					}
				}
			}

			return {
				type: this.specialEquipmentType,
				subType: this.dataToSend.subType,
				name: this.dataToSend.name,
				preproduction,
				shooting,
				energyType: this.dataToSend.energyType,
				dayInUse: parseFloat(this.dataToSend.dayInUse),
				energySourceUsed: this.dataToSend.energySourceUsed > 0,
				measurementOption: this.dataToSend.measurementOption > 0
			};
		},
		handleDismissedError() {
			this.stateError.show = false;
			this.stateError.msg = '';
		},
		handleClickAddEditConsumption() {
			this.showGeneratorConsumptionDialog = true;
		},
		handleGeneratorConsumptionDialogClose() {
			this.showGeneratorConsumptionDialog = false;
		},
		resetData() {
			this.loadingSubmit = false;
			this.equipmentType = 1;
			this.showGeneratorConsumptionDialog = false;
			this.productionPhaseSelected = [];
			this.stateError = {
				show: false,
				msg: ''
			};
			this.dataToSend = {
				type: 0,
				subType: 0,
				name: null,
				preproduction: 0,
				shooting: 0,
				energyType: 0,
				dayInUse: 0,
				specialEquipmentIdUpdate: 0,
				totalConsumption: 0,
				totalDistance: 0,
				totalCosts: 0,
				energySourceUsed: 0,
				measurementOption: 0
			};
			this.equipmentId = null;
			this.specialEquipmentId = 0;
		},
		emitEventClose() {
			this.$v.$reset();
			this.resetData();
			this.$emit('add-upd-special-equipment-dialog:close');
		}
	},

	validations() {
		return {
			dataToSend: {
				subType: { minValue: minValue(1) },
				name: { required },
				dayInUse: {
					required: this.isProductionRoomsType && this.dataToSend.measurementOption === 0 ? required : {},
					minValue: this.isProductionRoomsType && this.dataToSend.measurementOption === 0 ? minValue(1) : {}
				},
				energyType: {
					required: this.isGeneratorType ? required : {},
					minValue: this.isGeneratorType ? minValue(1) : {}
				}
			}
		};
	}
};
</script>

<style scoped></style>
