var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-document-package",
        title: _vm.FormMSG(79, "New equipment"),
        "header-class": "header-class-modal-doc-package",
        size: "xl",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "ok-title": _vm.FormMSG(36, "Save"),
        "ok-variant": "primary",
        "ok-disabled": _vm.loadingSubmit,
        "cancel-title": _vm.FormMSG(39, "Cancel"),
        "cancel-disabled": _vm.loadingSubmit,
        "cancel-variant": "custom-outline-gray",
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleOk.apply(null, arguments)
        },
        hidden: _vm.emitEventClose,
        cancel: _vm.emitEventClose,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-ok",
          fn: function () {
            return [
              _vm.loadingSubmit
                ? _c("b-spinner", { attrs: { small: "" } })
                : _vm._e(),
              _vm._v("\n\t\t" + _vm._s(_vm.FormMSG(36, "Save")) + "\n\t"),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        [
          _vm.stateError.show
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            variant: "danger",
                            show: "",
                            dismissible: "",
                          },
                          on: { dismissed: _vm.handleDismissedError },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.stateError.msg) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isGeneratorType
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "4", offset: "8" } }, [
                    _c("div", { staticClass: "d-flex justify-content-end" }, [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "fs-14 fw-700 pt-2 w-200-px" },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(64, "Generator No.")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value:
                                  _vm.equipmentId === null
                                    ? ""
                                    : _vm.equipmentId,
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "fieldset",
            {
              staticClass: "my-0 py-0 pb-0",
              class: `${
                _vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"
              }`,
            },
            [
              _c(
                "legend",
                {
                  staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                  class: `${
                    _vm.$screen.width <= 576
                      ? "card-inside"
                      : "scheduler-border"
                  }`,
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(390, "General")) +
                      "\n\t\t\t"
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "pt-3 pb-4" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(391, "Name") } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  state:
                                    _vm.getStateValidationField(
                                      "dataToSend.name"
                                    ),
                                  placeholder: _vm.FormMSG(754, "Enter name"),
                                },
                                model: {
                                  value: _vm.$v.dataToSend.name.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.dataToSend.name,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.dataToSend.name.$model",
                                },
                              }),
                              _vm.$v.dataToSend.name.$error &&
                              _vm.getStateValidationField("dataToSend.name") !==
                                null
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              755,
                                              "Please enter name"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.typeLabel } },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.generatorTypeOptions,
                                  state:
                                    _vm.getStateValidationField(
                                      "dataToSend.subType"
                                    ),
                                },
                                model: {
                                  value: _vm.$v.dataToSend.subType.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.dataToSend.subType,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.dataToSend.subType.$model",
                                },
                              }),
                              _vm.$v.dataToSend.subType.$error &&
                              _vm.getStateValidationField(
                                "dataToSend.subType"
                              ) !== null
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              759,
                                              "Please select generator type"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isGeneratorType || _vm.isTrailerType
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _c("b-form-group", {
                                staticClass: "pt-2",
                                attrs: {
                                  label: _vm.FormMSG(393, "Production phase"),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ ariaDescribedby }) {
                                        return [
                                          _c(
                                            "b-form-checkbox-group",
                                            {
                                              attrs: {
                                                "aria-describedby":
                                                  ariaDescribedby,
                                                name: "radio-production-phase",
                                              },
                                              model: {
                                                value:
                                                  _vm.productionPhaseSelected,
                                                callback: function ($$v) {
                                                  _vm.productionPhaseSelected =
                                                    $$v
                                                },
                                                expression:
                                                  "productionPhaseSelected",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    value: "preproduction",
                                                    "unchecked-value": false,
                                                    size: "lg",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        394,
                                                        "Preproduction"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    value: "shooting",
                                                    "unchecked-value": false,
                                                    size: "lg",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        395,
                                                        "Shooting"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3627297739
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isProductionRoomsType
                        ? _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _c("b-form-group", {
                                staticClass: "pt-2",
                                attrs: {
                                  label: _vm.FormMSG(
                                    3748,
                                    "Measurement option"
                                  ),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ ariaDescribedby }) {
                                        return [
                                          _c(
                                            "b-form-radio-group",
                                            {
                                              attrs: {
                                                id: "energy-source-used-radio",
                                                "aria-describedby":
                                                  ariaDescribedby,
                                                name: "radio",
                                              },
                                              model: {
                                                value:
                                                  _vm.dataToSend
                                                    .measurementOption,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataToSend,
                                                    "measurementOption",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dataToSend.measurementOption",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-radio",
                                                {
                                                  attrs: {
                                                    value: 0,
                                                    size: "lg",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        3749,
                                                        "Technical mean"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-form-radio",
                                                {
                                                  attrs: {
                                                    value: 1,
                                                    size: "lg",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(3750, "Costs")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2582571903
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "fieldset",
            {
              staticClass: "my-0 py-0 pb-0 mt-3",
              class: `${
                _vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"
              }`,
            },
            [
              _c(
                "legend",
                {
                  staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                  class: `${
                    _vm.$screen.width <= 576
                      ? "card-inside"
                      : "scheduler-border"
                  }`,
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" + _vm._s(_vm.secondSectionLabel) + "\n\t\t\t"
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "pt-3 pb-4" },
                [
                  _vm.isGeneratorType
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(491, "Energy type"),
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.energyTypeOptions,
                                      state: _vm.getStateValidationField(
                                        "dataToSend.energyType"
                                      ),
                                    },
                                    model: {
                                      value:
                                        _vm.$v.dataToSend.energyType.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataToSend.energyType,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.dataToSend.energyType.$model",
                                    },
                                  }),
                                  _vm.$v.dataToSend.subType.$error &&
                                  _vm.getStateValidationField(
                                    "dataToSend.energyType"
                                  ) !== null
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  798,
                                                  "Please select energy type"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                            [
                              _vm.$screen.width > 576
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(
                                          498,
                                          "Consumption distance"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            staticClass: "bg-color-grams-hair",
                                            attrs: {
                                              value:
                                                _vm.dataToSend.totalConsumption,
                                              disabled: "",
                                            },
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-input-group-text",
                                                {
                                                  staticClass:
                                                    "bg-color-north-wind text-color-white",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.consumptionGeneratorUnit
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isProductionRoomsType
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            [
                              !_vm.showCostForProductionRooms
                                ? _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              4798,
                                              "Day in used"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              state:
                                                _vm.getStateValidationField(
                                                  "dataToSend.dayInUse"
                                                ),
                                              placeholder: _vm.FormMSG(
                                                1258,
                                                "Enter day in used"
                                              ),
                                            },
                                            model: {
                                              value:
                                                _vm.$v.dataToSend.dayInUse
                                                  .$model,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.$v.dataToSend.dayInUse,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.dataToSend.dayInUse.$model",
                                            },
                                          }),
                                          _vm.$v.dataToSend.dayInUse.$error &&
                                          _vm.getStateValidationField(
                                            "dataToSend.dayInUse"
                                          ) !== null
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          1697,
                                                          "Please enter day in used"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showDistanceForProductionRooms
                                ? _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              1254,
                                              "Travel distance"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                staticClass:
                                                  "bg-color-grams-hair",
                                                attrs: {
                                                  value:
                                                    _vm.dataToSend
                                                      .totalDistance,
                                                  disabled: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "bg-color-north-wind text-color-white",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.distanceUnit
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showCostForProductionRooms
                                ? _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              5124,
                                              "Costs (excluded salaries)"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                staticClass:
                                                  "bg-color-grams-hair",
                                                attrs: {
                                                  value:
                                                    _vm.dataToSend.totalCosts,
                                                  disabled: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "bg-color-north-wind text-color-white",
                                                    },
                                                    [
                                                      _c("currency-svg", {
                                                        attrs: {
                                                          color: "#fff",
                                                          opacity: "0.85",
                                                          width: "15",
                                                          height: "15",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.showDistanceForProductionRooms
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { offset: "3", cols: "9" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex text-color-blue-streak fs-12",
                                          staticStyle: {
                                            "margin-top": "-12px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mr-1" },
                                            [
                                              _c(_vm.getLucideIcon("Info"), {
                                                tag: "component",
                                                attrs: { size: 14 },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1587,
                                                    "You can edit travel distance adding a new Carbon Entry or editing an existing entry under Sustainability > Carbon"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isTrailerType
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: "12",
                                    md: "3",
                                    lg: "3",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(
                                          1254,
                                          "Travel distance"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            staticClass: "bg-color-grams-hair",
                                            attrs: {
                                              value:
                                                _vm.dataToSend.totalDistance,
                                              disabled: "",
                                            },
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-input-group-text",
                                                {
                                                  staticClass:
                                                    "bg-color-north-wind text-color-white",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(_vm.distanceUnit) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex text-color-blue-streak fs-12",
                                    staticStyle: { "margin-top": "-12px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mr-1" },
                                      [
                                        _c(_vm.getLucideIcon("Info"), {
                                          tag: "component",
                                          attrs: { size: 14 },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              1587,
                                              "You can edit travel distance adding a new Carbon Entry or editing an existing entry under Sustainability > Carbon"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("div", [
            (_vm.isGeneratorType && _vm.dataToSend.subType === 3) ||
            _vm.isProductionRoomsType ||
            _vm.isTrailerType
              ? _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-3",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.thirdSectionLabel) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "pt-3 pb-4" }, [
                      _vm.isGeneratorType
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              1254,
                                              "Distance"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                staticClass:
                                                  "bg-color-grams-hair",
                                                attrs: { disabled: "" },
                                                model: {
                                                  value:
                                                    _vm.dataToSend
                                                      .totalDistance,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataToSend,
                                                      "totalDistance",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataToSend.totalDistance",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "bg-color-north-wind text-color-white",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.distanceUnit
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex text-color-blue-streak fs-12",
                                  staticStyle: { "margin-top": "-12px" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mr-1" },
                                    [
                                      _c(_vm.getLucideIcon("Info"), {
                                        tag: "component",
                                        attrs: { size: 14 },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            1587,
                                            "You can edit travel distance adding a new Carbon Entry or editing an existing entry under Sustainability > Carbon"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isProductionRoomsType || _vm.isTrailerType
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "6",
                                        lg: "6",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c("b-form-group", {
                                        staticClass: "pt-2",
                                        attrs: {
                                          label: _vm.FormMSG(
                                            3698,
                                            "Energy source used"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({
                                                ariaDescribedby,
                                              }) {
                                                return [
                                                  _c(
                                                    "b-form-radio-group",
                                                    {
                                                      attrs: {
                                                        id: "energy-source-used-radio",
                                                        "aria-describedby":
                                                          ariaDescribedby,
                                                        name: "radio-energy-source",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.dataToSend
                                                            .energySourceUsed,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dataToSend,
                                                            "energySourceUsed",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dataToSend.energySourceUsed",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-radio",
                                                        {
                                                          attrs: {
                                                            value: 0,
                                                            size: "lg",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1394,
                                                                "Filming location energy"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-form-radio",
                                                        {
                                                          attrs: {
                                                            value: 1,
                                                            size: "lg",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                1395,
                                                                "Generator"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4098324022
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }