<template>
	<div>
		<b-row v-if="stateError.show">
			<b-col>
				<b-alert variant="danger" show dismissible @dismissed="handleDismissedError">
					{{ stateError.msg }}
				</b-alert>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<div class="d-flex row align-items-center mt-3 hide-on-tablet">
					<b-col md="5">
						<b-form-group class="mb-0">
							<b-input-group v-if="$screen.width >= 992">
								<b-form-input v-model="filter" type="text" id="filterInput" :placeholder="this.FormMSG(37, 'Type to Search')" />
								<b-input-group-append class="cursor-pointer" id="groupAppendSearch">
									<b-input-group-text class="btn-search">
										<component
											:is="getLucideIcon('Search')"
											color="#FFFFFF"
											:size="16"
											class="icon"
											:stroke-width="2.5"
											v-if="filter.length === 0"
										/>
										<component
											:is="getLucideIcon('X')"
											color="#FFFFFF"
											:size="16"
											class="icon"
											:stroke-width="2.5"
											v-else
											@click="filter = ''"
										/>
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col md="7">
						<div class="d-flex justify-content-end">
							<b-button variant="primary" @click="handleClickAddGenerator">
								<div class="d-flex">
									<div class="mr-2">
										<component :is="getLucideIcon('Plus')" :size="16" />
									</div>
									<div>
										{{ btnAddLabel }}
									</div>
								</div>
							</b-button>
						</div>
					</b-col>
				</div>
			</b-col>
		</b-row>
		<b-row class="mt-3">
			<b-col>
				<b-table
					v-if="$screen.width >= 992"
					selected-variant="primary"
					hover
					selectable
					select-mode="single"
					responsive="sm"
					sticky-header="700px"
					:items="listEquipments"
					style="text-align: left"
					:fields="tableFields"
					:filter="filter"
					bordered
					striped
					small
					head-variant="dark"
					:empty-text="FormMSG(250, 'No data found')"
					show-empty
					tbody-tr-class="p-2"
					ref="table-green"
				>
					<template #head(actionSelected)="{}">
						<b-form-checkbox
							id="selectAllGreenList"
							v-if="listEquipments.length > 0"
							v-model="selectAll"
							size="lg"
							@change="handleInputSelectAll"
						/>
					</template>
					<template #cell(actionSelected)="{ item, index }">
						<b-form-checkbox v-model="item.checked" size="lg" @change="handleChangeItemChecked($event, item, index)" />
					</template>
					<template #cell(option)="{ item, index }">
						<div class="d-flex justify-content-center" style="min-width: 60px">
							<b-dropdown id="dropdown-green-action" no-caret dropleft boundary="window" class="btn-transparent" variant="none" size="sm" lazy>
								<template #button-content>
									<component :is="getLucideIcon('MoreVertical')" :size="16" />
								</template>
								<b-dropdown-item @click="handleClickReview(item)">
									<span class="text-color-rhapsody-in-blue">
										<component :is="getLucideIcon('CheckCircle')" :size="16" />&nbsp;{{ FormMSG(259, 'Review') }}
									</span>
								</b-dropdown-item>
							</b-dropdown>
						</div>
					</template>
				</b-table>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6">
				<div class="d-flex">
					<b-button
						size="md"
						variant="custom-outline-gray"
						class="mr-2 w-138-px"
						:disabled="listEquipmentsToReview.length === 0"
						@click="handleClickDeleteEquipments"
					>
						<b-spinner v-show="loadingDeleteAction" small />
						{{ FormMSG(158, 'Delete') }}
					</b-button>
				</div>
			</b-col>
		</b-row>

		<add-upd-special-equipment
			:open="showSpecialEquipmentDialog"
			:special-equipment-type="equipmentType"
			:edit-data="equipmentToEdit"
			:for-create="forCreateEquipment"
			@add-upd-special-equipment:action-success="handleAddUpdSpecialEquipmentActionSuccess"
			@add-upd-special-equipment-dialog:close="handleAddUpdSpecialEquipmentDialogClose"
		/>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import AddUpdSpecialEquipment from './AddUpdSpecialEquipment.vue';
import { getSpecialEquipmentList, getSpecialEquipment, delSpecialEquipments } from '@/cruds/special-equipment.crud';
import { store } from '@/store';
import {
	SPE_EQUIPMENT_TYPE_MENU,
	SPE_GENERATOR_ENERGY_TYPE_MENU,
	SPE_GENERATOR_TYPE,
	SPE_GENERATOR_TYPE_MENU,
	SPE_PRODUCTION_ROOMS_TYPE,
	SPE_TRAILER_TYPE,
	SPE_TRAILER_TYPE_MENU
} from '../../../../shared/constants';

export default {
	name: 'ListEquipmentsComponent',
	components: { AddUpdSpecialEquipment },

	mixins: [languageMessages, globalMixin],

	props: {
		equipmentType: { type: Number, required: true },
		btnAddLabel: { type: String, required: true }
	},

	data() {
		return {
			filter: '',
			listEquipments: [],
			equipmentToEdit: {},
			showSpecialEquipmentDialog: false,
			forCreateEquipment: true,
			selectAll: false,
			loadingDeleteAction: false,

			stateError: {
				show: false,
				msg: ''
			},

			listEquipmentsToReview: []
		};
	},

	computed: {
		tableFields() {
			let result = [
				{
					key: 'actionSelected',
					label: '',
					class: 'text-center actionSelected',
					sortable: false
				},
				{
					key: 'id',
					label: this.FormMSG(101, 'No.'),
					class: 'text-left',
					sortable: true
				},
				{
					key: 'name',
					label: this.FormMSG(102, 'Name'),
					class: 'text-left',
					sortable: true
				},
				{
					key: 'subType',
					label: this.FormMSG(103, 'Generator type'),
					formatter: (value) => {
						return this.getGeneratorTypeLabel(value);
					},
					class: 'text-left',
					sortable: true
				},
				{
					key: 'energyType',
					label: this.FormMSG(104, 'Energy type'),
					formatter: (value) => {
						return this.getEnergyTypeLabel(value);
					},
					class: 'text-left',
					sortable: true
				},
				{
					key: 'consumptionType',
					label: this.FormMSG(298, 'Consumption'),
					formatter: (value, key, item) => {
						return `${item.totalConsumption} ${this.consumptionGeneratorUnit(item.energyType)}`;
					},
					class: 'text-right',
					sortable: true
				},
				{
					key: 'travelDistance',
					label: this.FormMSG(106, 'Travel distance'),
					formatter: (value, key, item) => {
						if (item.subType === 3) {
							return `${item.totalDistance} ${this.FormMSG(1478, 'Km')}`;
						}

						return '';
					},
					class: 'text-right',
					sortable: true
				},
				{
					key: 'option',
					label: this.FormMSG(110, 'Option'),
					class: 'text-center',
					sortable: false
				}
			];

			if (this.isProductionRoomsType) {
				result = [
					{
						key: 'actionSelected',
						label: '',
						class: 'text-center actionSelected',
						sortable: false
					},
					{
						key: 'id',
						label: this.FormMSG(101, 'No.'),
						class: 'text-left',
						sortable: true
					},
					{
						key: 'name',
						label: this.FormMSG(102, 'Name'),
						class: 'text-left',
						sortable: true
					},
					{
						key: 'subType',
						label: this.FormMSG(3103, 'Equipment type'),
						formatter: (value) => {
							return this.getGeneratorTypeLabel(value);
						},
						class: 'text-left',
						sortable: true
					},
					{
						key: 'dayInUse',
						label: this.FormMSG(3105, 'Number of days used'),
						class: 'text-right',
						sortable: true
					},
					{
						key: 'totalDistance',
						label: this.FormMSG(106, 'Travel distance'),
						formatter: (value, key, item) => {
							if (item.subType === 3 || item.subType === 4 || item.subType === 5) {
								return `${item.totalDistance} ${this.FormMSG(1478, 'Km')}`;
							} 

							return '';
						},
						class: 'text-right',
						sortable: true
					},
					{
						key: 'totalCosts',
						label: this.FormMSG(3129, 'Costs'),
						class: 'text-right',
						formatter: (value) => {
							return this.number_format(parseFloat(value), 2, '.', ' ') + ' ' + store.state.myProfile.currencySymbol;
						},
						sortable: true
					},
					{
						key: 'option',
						label: this.FormMSG(110, 'Option'),
						class: 'text-center',
						sortable: false
					}
				];
			} else if (this.isTrailerType) {
				result = [
					{
						key: 'actionSelected',
						label: '',
						class: 'text-center actionSelected',
						sortable: false
					},
					{
						key: 'id',
						label: this.FormMSG(101, 'No.'),
						class: 'text-left',
						sortable: true
					},
					{
						key: 'name',
						label: this.FormMSG(102, 'Name'),
						class: 'text-left',
						sortable: true
					},
					{
						key: 'subType',
						label: this.FormMSG(7103, 'Trailer type'),
						formatter: (value) => {
							return this.getGeneratorTypeLabel(value);
						},
						class: 'text-left',
						sortable: true
					},
					{
						key: 'travelDistance',
						label: this.FormMSG(106, 'Travel distance'),
						formatter: (value, key, item) => {
							return `${item.totalDistance} ${this.FormMSG(1478, 'Km')}`;
						},
						class: 'text-right',
						sortable: true
					},
					{
						key: 'option',
						label: this.FormMSG(110, 'Option'),
						class: 'text-center',
						sortable: false
					}
				];
			}

			return result;
		},
		isGeneratorType() {
			return this.equipmentType === SPE_GENERATOR_TYPE;
		},
		isProductionRoomsType() {
			return this.equipmentType === SPE_PRODUCTION_ROOMS_TYPE;
		},
		isTrailerType() {
			return this.equipmentType === SPE_TRAILER_TYPE;
		}
	},

	async created() {
		await this.getListGenerators();
	},

	methods: {
		handleDismissedError() {
			this.stateError.show = false;
			this.stateError.msg = '';
		},
		handleClickDeleteEquipments() {
			const action = async () => {
				try {
					let listId = [];
					this.loadingDeleteAction = true;

					for (let i = 0; i < this.listEquipmentsToReview.length; i++) {
						const element = this.listEquipmentsToReview[i];
						listId.push(+element.id);
					}

					await delSpecialEquipments(listId);

					this.listEquipments = this.listEquipments.filter((option) => !listId.includes(+option.id));
					this.listEquipmentsToReview = [];
					this.selectAll = false;
					this.listEquipments = this.listEquipments.map((option) => ({
						...option,
						checked: false
					}));
					this.createToastForMobile(this.FormMSG(98, 'Success'), this.FormMSG(917, 'Generators deleted successfully!'));

					this.loadingDeleteAction = false;
				} catch (e) {
					this.stateError.msg = this.resolveGqlError(e);
					this.stateError.show = true;
					this.loadingDeleteAction = false;
				}
			};

			this.confirmModal(action, this.FormMSG(1421, 'Are you sure you want to delete it?'));
		},
		handleAddUpdSpecialEquipmentActionSuccess({ type, data, forCreate }) {
			if (type === this.equipmentType) {
				if (forCreate === true) {
					this.listEquipments.push(data);
				} else {
					this.listEquipments = this.listEquipments.map((option) => {
						if (+option.id === +data.id) {
							return data;
						}
						return option;
					});
				}
			}
		},
		async getListGenerators() {
			this.listEquipments = await getSpecialEquipmentList({ type: this.equipmentType });
		},
		handleChangeItemChecked(payload, item, index) {
			let findIndex = -1;
			if (this.listEquipmentsToReview.length > 0) {
				findIndex = this.listEquipmentsToReview.findIndex((option) => parseInt(option.id, 10) === parseInt(item.id, 10));
			}

			if (findIndex > -1) {
				if (payload === false) {
					this.listEquipmentsToReview.splice(findIndex, 1);

					if (this.listEquipmentsToReview.length === 0) {
						this.selectAll = false;
					}
				}
			} else if (findIndex === -1) {
				if (payload === true) {
					this.listEquipmentsToReview.push({
						...item,
						indexFromList: index
					});
					this.selectAll = true;
				}
			}
		},
		handleInputSelectAll(value) {
			this.listEquipmentsToReview = [];

			this.listEquipments = this.listEquipments.map((option) => {
				const result = {
					...option,
					checked: value
				};
				if (value) {
					this.listEquipmentsToReview.push(result);
				}

				return result;
			});
		},
		async handleClickReview(item) {
			this.forCreateEquipment = false;
			this.equipmentToEdit = await getSpecialEquipment(+item.id);
			this.showSpecialEquipmentDialog = true;
		},
		consumptionGeneratorUnit(energyType) {
			if (energyType === 1 || energyType === 2) {
				return this.FormMSG(956, 'L');
			} else if (energyType === 3) {
				return this.FormMSG(957, 'm3');
			}

			return this.FormMSG(958, 'kg');
		},
		getEnergyTypeLabel(value) {
			if (this.isGeneratorType) {
				return this.GetTextFromMenuNumberAndMenuValue(SPE_GENERATOR_ENERGY_TYPE_MENU, value);
			}

			return '';
		},
		getGeneratorTypeLabel(value) {
			if (this.isGeneratorType) {
				return this.GetTextFromMenuNumberAndMenuValue(SPE_GENERATOR_TYPE_MENU, value);
			} else if (this.isProductionRoomsType) {
				return this.GetTextFromMenuNumberAndMenuValue(SPE_EQUIPMENT_TYPE_MENU, value);
			} else if (this.isTrailerType) {
				return this.GetTextFromMenuNumberAndMenuValue(SPE_TRAILER_TYPE_MENU, value);
			}
		},
		handleClickAddGenerator() {
			this.forCreateEquipment = true;
			this.showSpecialEquipmentDialog = true;
		},
		handleAddUpdSpecialEquipmentDialogClose() {
			this.showSpecialEquipmentDialog = false;
		}
	}
};
</script>

<style scoped></style>
