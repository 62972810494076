var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("list-equipments-component", {
        attrs: {
          "equipment-type": _vm.equipmentType,
          "btn-add-label": _vm.FormMSG(264, "Add equipment"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }