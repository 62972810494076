var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.stateError.show
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    {
                      attrs: { variant: "danger", show: "", dismissible: "" },
                      on: { dismissed: _vm.handleDismissedError },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.stateError.msg) + "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex row align-items-center mt-3 hide-on-tablet",
              },
              [
                _c(
                  "b-col",
                  { attrs: { md: "5" } },
                  [
                    _c(
                      "b-form-group",
                      { staticClass: "mb-0" },
                      [
                        _vm.$screen.width >= 992
                          ? _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "text",
                                    id: "filterInput",
                                    placeholder: this.FormMSG(
                                      37,
                                      "Type to Search"
                                    ),
                                  },
                                  model: {
                                    value: _vm.filter,
                                    callback: function ($$v) {
                                      _vm.filter = $$v
                                    },
                                    expression: "filter",
                                  },
                                }),
                                _c(
                                  "b-input-group-append",
                                  {
                                    staticClass: "cursor-pointer",
                                    attrs: { id: "groupAppendSearch" },
                                  },
                                  [
                                    _c(
                                      "b-input-group-text",
                                      { staticClass: "btn-search" },
                                      [
                                        _vm.filter.length === 0
                                          ? _c(_vm.getLucideIcon("Search"), {
                                              tag: "component",
                                              staticClass: "icon",
                                              attrs: {
                                                color: "#FFFFFF",
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                            })
                                          : _c(_vm.getLucideIcon("X"), {
                                              tag: "component",
                                              staticClass: "icon",
                                              attrs: {
                                                color: "#FFFFFF",
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.filter = ""
                                                },
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("b-col", { attrs: { md: "7" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.handleClickAddGenerator },
                        },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              { staticClass: "mr-2" },
                              [
                                _c(_vm.getLucideIcon("Plus"), {
                                  tag: "component",
                                  attrs: { size: 16 },
                                }),
                              ],
                              1
                            ),
                            _c("div", [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.btnAddLabel) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _vm.$screen.width >= 992
                ? _c("b-table", {
                    ref: "table-green",
                    staticStyle: { "text-align": "left" },
                    attrs: {
                      "selected-variant": "primary",
                      hover: "",
                      selectable: "",
                      "select-mode": "single",
                      responsive: "sm",
                      "sticky-header": "700px",
                      items: _vm.listEquipments,
                      fields: _vm.tableFields,
                      filter: _vm.filter,
                      bordered: "",
                      striped: "",
                      small: "",
                      "head-variant": "dark",
                      "empty-text": _vm.FormMSG(250, "No data found"),
                      "show-empty": "",
                      "tbody-tr-class": "p-2",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "head(actionSelected)",
                          fn: function ({}) {
                            return [
                              _vm.listEquipments.length > 0
                                ? _c("b-form-checkbox", {
                                    attrs: {
                                      id: "selectAllGreenList",
                                      size: "lg",
                                    },
                                    on: { change: _vm.handleInputSelectAll },
                                    model: {
                                      value: _vm.selectAll,
                                      callback: function ($$v) {
                                        _vm.selectAll = $$v
                                      },
                                      expression: "selectAll",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(actionSelected)",
                          fn: function ({ item, index }) {
                            return [
                              _c("b-form-checkbox", {
                                attrs: { size: "lg" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleChangeItemChecked(
                                      $event,
                                      item,
                                      index
                                    )
                                  },
                                },
                                model: {
                                  value: item.checked,
                                  callback: function ($$v) {
                                    _vm.$set(item, "checked", $$v)
                                  },
                                  expression: "item.checked",
                                },
                              }),
                            ]
                          },
                        },
                        {
                          key: "cell(option)",
                          fn: function ({ item, index }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                  staticStyle: { "min-width": "60px" },
                                },
                                [
                                  _c(
                                    "b-dropdown",
                                    {
                                      staticClass: "btn-transparent",
                                      attrs: {
                                        id: "dropdown-green-action",
                                        "no-caret": "",
                                        dropleft: "",
                                        boundary: "window",
                                        variant: "none",
                                        size: "sm",
                                        lazy: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "button-content",
                                            fn: function () {
                                              return [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "MoreVertical"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: { size: 16 },
                                                  }
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleClickReview(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-color-rhapsody-in-blue",
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  "CheckCircle"
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: { size: 16 },
                                                }
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.FormMSG(259, "Review")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3876227314
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-2 w-138-px",
                    attrs: {
                      size: "md",
                      variant: "custom-outline-gray",
                      disabled: _vm.listEquipmentsToReview.length === 0,
                    },
                    on: { click: _vm.handleClickDeleteEquipments },
                  },
                  [
                    _c("b-spinner", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loadingDeleteAction,
                          expression: "loadingDeleteAction",
                        },
                      ],
                      attrs: { small: "" },
                    }),
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(158, "Delete")) +
                        "\n\t\t\t\t"
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("add-upd-special-equipment", {
        attrs: {
          open: _vm.showSpecialEquipmentDialog,
          "special-equipment-type": _vm.equipmentType,
          "edit-data": _vm.equipmentToEdit,
          "for-create": _vm.forCreateEquipment,
        },
        on: {
          "add-upd-special-equipment:action-success":
            _vm.handleAddUpdSpecialEquipmentActionSuccess,
          "add-upd-special-equipment-dialog:close":
            _vm.handleAddUpdSpecialEquipmentDialogClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }